<template>
  <div>
    <div v-if="items.length > 0">
      <h5 class="text-warning p-2 mb-0">
        ⚠ Add the restaurant's postcode to the scope of a hub.
      </h5>

      <CDataTable
        class="issue-table table-outline"
        hover
        :items="items"
        :fields="fields"
        :loading="loading"
        :noItemsView="{
          noResults: 'No filtering results available!',
          noItems: 'No issue found!',
        }"
        head-color="light"
        no-sorting
      >
        <td slot="restaurant" slot-scope="{item}">
          <div class="d-flex align-items-center">
            <div class="c-avatar mr-2">
              <CImg
                :src="item.restaurant_logo_path"
                class="c-avatar-img"
                style="width: 36px; height: 36px"
                placeholderColor="lightgray"
              />
            </div>
            <div>
              <CLink :to="{name: 'View Restaurant', params: { id: item.id }}" class="text-muted">{{ item.restaurant_name }}</CLink>
            </div>
          </div>
        </td>

        <td slot="location" slot-scope="{item}">
          <strong>{{item.restaurant_city }}</strong> <small>{{item.restaurant_postcode }}</small>
        </td>

        <td slot="fix" slot-scope="{item}" class="text-center">
          <CButton
            :to="{ name: 'Hubs' }"
            v-c-tooltip="{
              content: 'Hub List',
              placement: 'top',
            }"
            size="sm"
            color="info"
            variant="ghost"
            shape="pill"
          >
            <CIcon name="cil-lan" />
          </CButton>
        </td>
      </CDataTable>
    </div>

    <div v-else>
      <CSpinner v-if="loading" />
      <h6 v-else>No issue found!</h6>
    </div>
  </div>
</template>

<script>

export default {
  name: "IssueRestaurantsWithoutHubCoverage",
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
      loading: false,
      fields: [
        { key: "restaurant" },
        { key: "location", _style: "width: 300px;" },
        { key: "fix", _style: "width: 140px; text-align: center;" },
      ],
    };
  },

  watch: {
    active: async function (val) {
      val && await this.getData();
    },
  },

  async mounted() {
    this.active && await this.getData();
  },

  methods: {
    async getData() {
      try {
        this.loading = true;
        const { data } = await this.$axios.get(this.$backend.ISSUE.GET, {
          params: {
            issue: "restaurants-without-hub-coverage",
          },
        })
        this.items = data.data;
      } catch (error) {
        this.$toast.error(`${error}`);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.issue-table table {
  margin-bottom: 0;
}

.issue-table table th, .issue-table table td {
  padding: 0.75rem;
  vertical-align: middle;
}
</style>
