<template>
  <div>
    <div v-if="!loading" class="d-flex flex-column flex-md-row align-items-end justify-content-between mb-1">

      <h5 class="text-warning p-2 mb-0">
        <span v-if="stats?.difference > 0">🛈 Compared to the previous year, {{ stats.difference }} fewer holidays have been scheduled for this year.</span>
      </h5>

      <CButton color="primary" :to="{ name: 'Holidays' }">
        <CIcon name="cil-header" class="align-bottom" /> <strong>View</strong> Holidays
      </CButton>
    </div>

    <CDataTable v-if="items.length > 0"
      class="issue-table table-outline"
      hover
      :items="items"
      :fields="fields"
      :loading="loading" :noItemsView="{
        noResults: 'No filtering results available!',
        noItems: 'No holidays found!',
      }"
      head-color="light"
      no-sorting
    >
      <!-- Name -->
      <template #name="{ item }">
        <td>
          <div class="d-flex align-items-center flex-gap-2">
            <CIcon :content="$options.flagSet[item.country?.flag]" size="lg" />
            <strong>{{ item.holiday_name?.name }}</strong>
          </div>
        </td>
      </template>

      <!-- Start -->
      <template #start_date="{ item }">
        <td>{{ moment(item.start_date).format('LL') }}</td>
      </template>

      <!-- End -->
      <template #end_date="{ item }">
        <td>{{ item.end_date ? moment(item.end_date).format('LL') : '' }}</td>
      </template>
    </CDataTable>

    <div v-else>
      <CSpinner v-if="loading" />
      <h5 v-else class="text-warning p-2 mb-0">
        ⚠ There are no scheduled holidays for the next six months!
      </h5>
    </div>
  </div>
</template>

<script>
import { flagSet } from '@coreui/icons'

export default {
  name: "HolidaysForNextSixMonths",
  flagSet,
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
      stats: null,
      loading: false,
      fields: [
        { key: "name", label: "Name", _style: "min-width: 250px;" },
        { key: "start_date", label: "Start", sorter: true, _style: "min-width: 140px; width: 300px;" },
        { key: "end_date", label: "End", _style: "min-width: 140px; width: 300px;" },
      ],
    };
  },

  watch: {
    active: async function (val) {
      val && await this.getData();
    },
  },

  async mounted() {
    this.active && await this.getData();
  },

  methods: {
    async getData() {
      this.loading = true;
      const { data } = await this.$axios.get(this.$backend.ISSUE.GET, {
        params: {
          issue: "holidays-for-next-six-months",
        },
      })
      this.items = data.data.map((el) => {
        return {
          ...el,
          country: this.authCountries.find((c) => c.value === el.country_id),
        };
      });
      this.stats = data.stats;
      this.loading = false;
    },
  },
};
</script>

<style>
.issue-table table {
  margin-bottom: 0;
}

.issue-table table th,
.issue-table table td {
  padding: 0.75rem;
  vertical-align: middle;
}
</style>
