<template>
  <DashboardRestricted v-if="restrictedUser" />
  <Dashboard v-else-if="authUser" />
</template>

<script>

import DashboardRestricted from './DashboardRestricted.vue';
import Dashboard from './Dashboard.vue';

export default {
  components: {
    DashboardRestricted,
    Dashboard
  }
};
</script>
