<template>
  <form @submit.prevent="addItem" autocomplete="off">
    <div class="memo">
      <CTextarea rows="2" class="memo-input" v-model="newItem" />
      <button class="btn btn-success btn-add" :disabled="newItem === ''">
        <CIcon name="cil-plus" />
      </button>
    </div>

    <ul class="memo-list">
      <li class="memo-list-item" v-for="(item, index) in items" :key="index" v-bind:class="{ completed: item.completed }">
        <CInputCheckbox
          :label="item.note"
          custom
          :checked.sync="item.completed"
          @update:checked="updateItem(item)"
        />
        <CButton class="btn-delete" size="sm" color="danger" variant="ghost" shape="pill"
          @click="deleteItem(item, index)">
          <CIcon name="cil-trash" />
        </CButton>
      </li>
    </ul>
  </form>
</template>

<script>
export default {
  data() {
    return {
      newItem: "",
      items: [],
    };
  },

  beforeMount() {
    this.items = JSON.parse(localStorage.getItem('my-memos')) || [];
  },

  async mounted() {
    await this.getItems();
  },

  methods: {
    async getItems() {
      await this.$axios
        .get(this.$backend.MEMO.GET_ALL)
        .then(({ data }) => {
          this.items = data;
          this.updateStorage();
        });
    },

    async addItem() {
      const note = this.newItem;
      if (note !== "") {
        try {
          this.newItem = "";
          const { data } = await this.$axios.post(this.$backend.MEMO.ADD, { note: note });
          this.items.unshift(data);
          this.updateStorage();
        } catch (error) {
          this.$toast.error(`${error.response.data.message}`);
        }
      }
    },

    async updateItem(item) {
      try {
        await this.$axios.put(this.$backend.MEMO.UPDATE.replace("{id}", item.id), { completed: item.completed });
        this.updateStorage();
      } catch (error) {
        this.$toast.error(`${error.response.data.message}`);
      }
    },

    async deleteItem(item, index) {
      try {
        this.items.splice(index, 1);
        await this.$axios.delete(this.$backend.MEMO.DELETE.replace("{id}", item.id));
        this.updateStorage();
      } catch (error) {
        this.$toast.error(`${error.response.data.message}`);
      }
    },

    updateStorage() {
      localStorage.setItem('my-memos', JSON.stringify(this.items));
    }
  },
};
</script>

<style lang="scss">
@import "src/assets/scss/memo.scss";
</style>
