<template>
  <div>
    <CRow>
      <CCol sm="6" xl="4">
        <CCard>
          <CCardHeader class="pb-0 border-bottom-0">
            <h5 class="card-title mb-0"><CIcon name="cil-bolt"/> My Tasks Progress</h5>
          </CCardHeader>
          <CCardBody class="top-card-body">
            <CChartDoughnut
              :datasets="taskChartDatasets"
              :labels="taskChartLabels"
            />
          </CCardBody>
        </CCard>
      </CCol>
      <CCol sm="6" xl="8">
        <CCard>
          <CCardHeader class="pb-0 border-bottom-0">
            <h5 class="card-title mb-0"><CIcon name="cil-note-add"/> Memo</h5>
          </CCardHeader>
          <CCardBody class="top-card-body">
            <Memo />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <CCard>
      <CCardHeader>
        <CIcon name="cil-bolt" />
        <h5 class="d-inline ml-2">My Open Tasks ({{ myPendingTasks.length }})</h5>
        <div class="card-header-actions">
          <CButton color="primary" :to="{ name: 'Task List'}">
            <CIcon name="cil-bolt" size="lg" /> Show <strong>All Tasks</strong>
          </CButton>
        </div>
      </CCardHeader>

      <CCardBody>
        <!-- Task Table -->
        <TaskTable
          :tasks="myPendingTasks"
          :loading="loading"
          @taskChanged="getMyTasks"
        />
      </CCardBody>
    </CCard>


    <CCard>
      <CCardHeader>
        <CIcon name="cil-bell-exclamation" />
        <h5 class="d-inline ml-2">Issues</h5>
      </CCardHeader>
      <CCardBody>
        <div>
          <CCard class="mb-0" v-for="issue in issues">
            <CButton
              block
              color="link"
              class="text-left shadow-none card-header"
              @click="accordion = accordion === issue.id ? false : issue.id"
            >
              <h5 class="m-0">• {{ issue.title }}</h5>
            </CButton>
            <CCollapse :show="accordion === issue.id">
              <CCardBody class="p-1">
                <component :is="issue.component" :active.sync="accordion === issue.id"></component>
              </CCardBody>
            </CCollapse>
          </CCard>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { CChartDoughnut } from '@coreui/vue-chartjs'
import Memo from "@/views/memo/List";
import TaskTable from "@/views/crm/task/TaskTable";
import HolidaysForNextSixMonths from '@/components/Issues/HolidaysForNextSixMonths.vue'
import RestTempClosedExpired from '@/components/Issues/RestTempClosedExpired.vue'
import RestWithLast3RealOrdersDeleted from '@/components/Issues/RestWithLast3RealOrdersDeleted.vue'
import RestaurantsWithoutHubCoverage from '@/components/Issues/RestaurantsWithoutHubCoverage.vue'

export default {
  name: "Dashboard",
  components: {
    Memo,
    CChartDoughnut,
    TaskTable,
    HolidaysForNextSixMonths,
    RestTempClosedExpired,
    RestWithLast3RealOrdersDeleted,
    RestaurantsWithoutHubCoverage
  },
  data() {
    return {
      myTasks: [],
      loading: false,
      accordion: null,
      issues: [
        { id: 0, title: "Holidays for the next six months", component: "HolidaysForNextSixMonths" },
        { id: 1, title: "Restaurants published and temporarily closed but expired", component: "RestTempClosedExpired" },
        { id: 2, title: "Restaurants with the last 3 real orders deleted", component: "RestWithLast3RealOrdersDeleted" },
        { id: 3, title: "Restaurants with delivery service not scoped by a hub", component: "RestaurantsWithoutHubCoverage" },
      ],
    };
  },
  computed: {
    currentDate() {
      const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      const current = new Date();
      return `${monthNames[current.getMonth()]} ${current.getFullYear()}`;
    },

    taskChartLabels() {
      return this.$globalVar.emailLabels.map((el) => el.label);
    },

    taskChartDatasets() {
      return [
        {
          backgroundColor: ["#f9b115", "#39f", "#2eb85c"],
          data: this.taskChartLabels.map((el) => {
            return this.groupedMyTasks[el] ? this.groupedMyTasks[el].count : 0;
          }),
          borderWidth: 4,
        },
      ];
    },

    groupedMyTasks() {
      // Number of tasks grouped by label within a task
      return this.myTasks
        .filter((el) => el.is_my_task)
        .reduce((acc, el) => {
          const label = el.labelObj.label;

          if (acc[label]) {
            acc[label].count += 1;
          } else {
            acc[label] = {
              count: 1,
            };
          }

          return acc;
        }, {});
    },

    myPendingTasks() {
      return this.myTasks.filter((el) => el.is_my_task && el.label !== 'done');
    },
  },

  beforeMount() {
    this.myTasks = JSON.parse(localStorage.getItem('my-tasks')) || [];
  },

  async mounted() {
    await this.getMyTasks();
  },

  methods: {
    async getMyTasks() {
      var params = { };

      this.loading = true;
      await this.$axios
        .get(this.$backend.CRM_TASKS.GET_MY, { params })
        .then((response) => {
          this.myTasks = response.data.map((el) => {
            return {
              ...el,
              labelObj: this.getLabel(el.label),
              deleting: false
            };
          });
        })
        .finally(() => {
          this.loading = false;
          localStorage.setItem('my-tasks', JSON.stringify(this.myTasks));
        });
    },

    getLabel(value) {
      return value ? this.$globalVar.emailLabels.find((el) => el.value === value) : null;
    },
  }
};
</script>

<style>
.top-card-body {
  height: 20em;
  padding: 1rem
}
</style>
