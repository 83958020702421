<template>
  <CCard>
    <CCardHeader class="pb-0 border-bottom-0">
      <h4 class="d-flex align-items-center flex-gap-2">
        <CIcon name="cil-chart-line" size="2xl" /> Revenue Report
      </h4>
    </CCardHeader>
    <CCardBody>
      <div v-if="revenue" class="row mt-3" style="font-size: 1.15rem;">
        <div class="col-md-6">
          <p><strong>Month:</strong> {{ revenue.dates.month | ucfirst }}</p>
          <p><strong>Start Date:</strong> {{ revenue.dates.startDate }}</p>
          <p><strong>End Date:</strong> {{ revenue.dates.endDate }}</p>
        </div>
        <div class="col-md-6 h3">
          <!-- <p><strong>Order Count:</strong> {{ revenue.orderCount }}</p> -->
          <p><strong>Total Revenue:</strong> {{ parseFloat(revenue.totalRevenue) | toCurrency }}</p>
        </div>
      </div>
    </CCardBody>

    <CElementCover :opacity="0.4" v-show="loading">
      <CSpinner size="5xl" grow color="primary" />
    </CElementCover>
  </CCard>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DashboardRestricted",
  data() {
    return {
      loading: false,
      revenue: null,
    };
  },

  computed: {
    ...mapGetters(["errors"]),
  },

  async mounted() {
    await this.revenueByOriginCountryLastMonth();
  },

  methods: {
    async revenueByOriginCountryLastMonth() {
      this.loading = true;

      try {
        const { data } = await this.$axios.get(this.$backend.REPORTS.REVENUE_BY_ORIGIN_COUNTRY_LAST_MONTH);
        this.revenue = data;
      } catch (error) {
        this.$toast.error(typeof this.errors === 'object'
          ? Object.values(this.errors).join()
          : this.errors
        );
      } finally {
        this.loading = false;
      }
    },
  }
};
</script>
