<template>
  <div>
    <CDataTable
      v-if="items.length > 0"
      class="issue-table table-outline"
      hover
      :items="items"
      :fields="fields"
      :loading="loading"
      :noItemsView="{
        noResults: 'No filtering results available!',
        noItems: 'No issue found!',
      }"
      head-color="light"
      no-sorting
    >
      <td slot="restaurant" slot-scope="{item}">
        <div class="d-flex align-items-center">
          <div class="c-avatar mr-2">
            <CImg
              :src="item.restaurant_logo_path"
              class="c-avatar-img"
              style="width: 36px; height: 36px"
              placeholderColor="lightgray"
            />
          </div>
          <div>
            <CLink :to="{name: 'View Restaurant', params: { id: item.id }}" class="text-muted">{{ item.restaurant_name }}</CLink>
          </div>
        </div>
      </td>

      <td slot="location" slot-scope="{item}">
        <strong>{{item.restaurant_city }}</strong> <small>{{item.restaurant_postcode }}</small>
      </td>

      <td slot="orders" slot-scope="{item}">
        <CLink :to="{name: 'RestaurantOrderList', params: { restaurant_id: item.id }}" class="text-muted">View Orders</CLink>
      </td>

      <td slot="fix" slot-scope="{item}" class="text-center">
        <CButton
          :to="{ name: 'View Restaurant', params: { id: item.id } }"
          v-c-tooltip="{
            content: 'View Restaurant',
            placement: 'top',
          }"
          size="sm"
          color="primary"
          variant="ghost"
          shape="pill"
        >
          <CIcon name="cil-restaurant" />
        </CButton>
      </td>
    </CDataTable>

    <div v-else>
      <CSpinner v-if="loading" />
      <h6 v-else>No issue found!</h6>
    </div>
  </div>
</template>

<script>

export default {
  name: "IssueRestWithLast3RealOrdersDeleted",
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
      loading: false,
      fields: [
        { key: "restaurant" },
        { key: "location", _style: "width: 300px;" },
        { key: "orders", _style: "width: 240px;" },
        { key: "fix", _style: "width: 140px; text-align: center;" },
      ],
    };
  },

  watch: {
    active: async function (val) {
      val && await this.getData();
    },
  },

  async mounted() {
    this.active && await this.getData();
  },

  methods: {
    async getData() {
      try {
        this.loading = true;
        const { data } = await this.$axios.get(this.$backend.ISSUE.GET, {
          params: {
            issue: "restaurants-with-the-last-3-real-orders-deleted",
          },
        })
        this.items = data.data;
      } catch (error) {
        this.$toast.error(`${error}`);
        // console.error("Error fetching data:", error.response.data.message);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.issue-table table {
  margin-bottom: 0;
}

.issue-table table th, .issue-table table td {
  padding: 0.75rem;
  vertical-align: middle;
}
</style>
